<template>
  <v-row class="lahan-detail" :key="'lahan-detail' + componentKey">
    <v-col md="4" xl="3">
      <lahan-mou-print v-if="
        data.main_lahan &&
        data.main_lahan.approve == 2 &&
        $_sys.isAllowed('lahan-print-mou-create')
      " :mouData="mouData || null" :lahanData="data.main_lahan" :modalKey="printModal" />
      <lahan-appendix-print v-if="
        data.main_lahan &&
        data.main_lahan.approve == 2 &&
        mouData &&
        ![0, 2].includes(mouData.mou_status) &&
        $_sys.isAllowed('lahan-print-appendix-create')
      " :mouData="mouData || null" :lahanData="data.main_lahan" :modalKey="printAppendixModal"
        :imageData="polygonImageData" :trees="data.lahan_detail" />
      <v-card data-aos="fade-up" data-aos-delay="100" data-aos-duration="800" class="scooping-visit-detail-card mb-5">
        <v-card-title>
          <v-icon large class="mr-2" @click="$router.go(-1)">mdi-arrow-left-circle</v-icon>
          <h5 class="mb-0 pb-0">Detail Lahan</h5>
        </v-card-title>
        <div class="lahan-side-wrapper">
          <div class="lahan-side-list">
            <h4>Action</h4>

            <div class="lahan-side-item-wrapper" style="flex-wrap: wrap; width: 100%" v-if="data.main_lahan">
              <!-- UNVERIFIKASI CARBON -->
              <div class="lahan-side-item d-flex flex-col" style="flex-wrap: wrap" v-if="getProject() === 'carbon'">
                <div>
                  <v-btn v-if="
                    $_sys.isAllowed('lahan-fc-unverification-create') &&
                    [0, 1, null].includes(data.main_lahan.fc_complete_data) &&
                    data.main_lahan.updated_gis === 'belum'
                  " variant="danger" class="mr-1 mb-1" @click="unverificationData('fc_complete_data')">
                    <v-icon>mdi-undo-variant</v-icon>
                    <span>Unverifikasi Kelengkapan Data</span>
                  </v-btn>

                  <v-btn v-if="$_sys.isAllowed('lahan-um-unverification-create')" variant="danger" class="mr-1 mb-1"
                    @click="unverificationData('um_seed')">
                    <v-icon>mdi-undo-variant</v-icon>
                    <span>Unverifikasi Perubahan Bibit</span>
                  </v-btn>

                  <v-btn v-if="$_sys.isAllowed('lahan-gis-unverification-create')" variant="danger" class="mr-1 mb-1"
                    @click="unverificationData('gis')">
                    <v-icon>mdi-undo-variant</v-icon>
                    <span>Unverifikasi GIS</span>
                  </v-btn>

                  <v-btn v-if="$_sys.isAllowed('lahan-um-unverification-create')" variant="danger" class="mr-1 mb-1"
                    @click="unverificationData('um_unverification')">
                    <v-icon>mdi-undo-variant</v-icon>
                    <span>Unverifikasi
                      {{ data.main_lahan.approve == 2 ? "UM" : "FC" }}</span>
                  </v-btn>
                </div>

                <v-btn v-if="
                  !openGisEdit &&
                  $_sys.isAllowed('lahan-gis-verification-create') &&
                  data.main_lahan.fc_complete_data == 1
                " variant="success" class="mr-1 mb-1" @click="
                  openGisEdit = true;
                verifRole = 'gis';
                ">Verifikasi GIS</v-btn>
                <v-btn v-if="
                  !openFcCompleteData &&
                  !openFc &&
                  $_sys.isAllowed('lahan-fc-verification-create') &&
                  data.main_lahan &&
                  ((data.main_lahan.fc_complete_data == null &&
                    data.main_lahan.updated_gis.toLowerCase() === 'belum') ||
                    data.main_lahan.updated_gis.toLowerCase() === 'sudah')
                " variant="success" class="mr-1 mb-2" @click="toggleVerificationFc">Verifikasi FC</v-btn>
                <v-btn v-if="
                  !openFcAssestment &&
                  data.main_lahan &&
                  (data.main_lahan.updated_gis.toLowerCase() === 'sudah')
                " variant="success" class="mr-1 mb-2" @click="openFcAssestment = true;verifRole = 'fc-assestment'">
                Lahan Assestment</v-btn>
                <v-btn v-if="
                  !openUm &&
                  $_sys.isAllowed('lahan-um-verification-create') &&
                  data.main_lahan &&
                  data.main_lahan.updated_gis.toLowerCase() === 'sudah' &&
                  data.main_lahan.approve == 1
                " variant="success" class="mr-1 mb-2" @click="
                  openUm = true;
                verifRole = 'um';
                ">Verifikasi UM</v-btn>
                <v-btn v-if="$_sys.isAllowed('lahan-gis-verification-update')" variant="info" class="mr-1 mb-2"
                  @click="onRecalculatePlantingArea" :disabled="loading"><v-icon>mdi-calculator</v-icon><span
                    class="ml-1">Recalculate Planting Area</span></v-btn>

                <!-- PRINT & PREVIEW MOU -->
                <v-btn variant="primary" class="mr-1 mb-2 d-flex flex-row align-items-center" v-if="
                  $_sys.isAllowed('lahan-print-mou-create') &&
                  data.main_lahan &&
                  data.main_lahan.approve == 2
                " @click="printModal += 1">
                  <v-icon v-if="btnLabelLegalitasMOU.toLowerCase().includes('upload')">mdi-file-upload-outline</v-icon>
                  <v-icon v-else-if="
                    btnLabelLegalitasMOU.toLowerCase().includes('revisi')
                  ">mdi-file-document-edit-outline</v-icon>
                  <v-icon v-else-if="
                    btnLabelLegalitasMOU.toLowerCase().includes('verifikasi')
                  ">mdi-file-alert-outline</v-icon>
                  <v-icon v-else-if="
                    btnLabelLegalitasMOU.toLowerCase().includes('lihat')
                  ">mdi-file-eye-outline</v-icon>
                  <v-icon v-else>mdi-printer-outline</v-icon>
                  <span class="ml-1">{{ btnLabelLegalitasMOU }}</span>
                </v-btn>

                <!-- PRINT APPENDIX LAHAN -->

                <v-btn variant="primary" @click="onOpenAppendixPrint(lahan.key2)" v-if="
                  data.main_lahan &&
                  $_sys.isAllowed('lahan-print-appendix-create') &&
                  mouData &&
                  mouData.mou_status == 1
                " v-for="(lahan, i) in mouData.lahans" :key="`lahan-${i}`" class="mb-2">
                  <v-icon>mdi-printer-outline</v-icon>
                  <span v-if="lahan.key2 === data.main_lahan.lahan_no">Print Appendix {{ lahan.key2 }}</span>
                  <span v-else>Print Appendix</span>
                </v-btn>
              </div>
              <!-- UNVERIFIKASI & VERIF NON CARBON -->
              <div class="lahan-side-item d-flex flex-col" style="flex-wrap: wrap" v-if="getProject() === 'non-carbon'">
                <!-- UNVERIFIKASI -->
                <v-btn v-if="
                  $_sys.isAllowed('lahan-fc-unverification-create') &&
                  [0, 1, null].includes(data.main_lahan.fc_complete_data)
                " variant="danger" class="mr-1 mb-1" @click="unverificationData('fc_complete_data')">
                  <v-icon>mdi-undo-variant</v-icon>
                  <span>Unverifikasi Kelengkapan Data</span>
                </v-btn>

                <v-btn v-if="$_sys.isAllowed('lahan-um-unverification-create')" variant="danger" class="mr-1 mb-1"
                  @click="unverificationData('um_unverification')">
                  <v-icon>mdi-undo-variant</v-icon>
                  <span>Unverifikasi UM</span>
                </v-btn>
                <!-- VERIFIKASI -->
                <v-btn v-if="
                  !openGisEdit &&
                  $_sys.isAllowed('lahan-fc-verification-create') &&
                  data.main_lahan &&
                  data.main_lahan.approve == 0
                " variant="success" class="mr-1 mb-2" @click="openFcNonCarbon = true">Verifikasi FC</v-btn>

                <v-btn v-if="
                  $_sys.isAllowed('lahan-um-verification-create') &&
                  data.main_lahan &&
                  data.main_lahan.approve == 1
                " variant="success" class="mr-1 mb-2" @click="verifUmNonCarbon()">Verifikasi Data Lahan</v-btn>
              </div>
            </div>
          </div>
          <!-- 
          <lahan-gis-verification
            v-if="
              data.main_lahan &&
              ['4', '13', '14', '19', '20'].includes(
                this.$store.state.User.role
              ) &&
              openGisEdit
            "
            :data="data.main_lahan"
            :questions="
              data.lahan_term_question_list.filter((x) => {
                if (verifRole == 'gis') return x.role_id == 14;
                else if (verifRole == 'um') return x.role_id == 20;
                else if (verifRole == 'fc') return x.role_id == 19;
              })
            "
            :answers="data.lahan_term_answer_list"
            :role="verifRole"
            :isCarbonProject="
              Array.isArray(data.lahan_project) &&
              data.lahan_project.length > 0 &&
              data.lahan_project[0].project_planting_purposes_code == 'carbon'
            "
            :polygonGisArea="polygonGisArea"
            @success="
              componentKey += 1;
              openGisEdit = false;
              getData();
            "
            @polygon_change="onChangePolygon"
            @close="
              openGisEdit = false;
              verifRole = null;
            "
          /> -->

          <lahan-verification-gis v-if="
            data.main_lahan &&
            $_sys.isAllowed('lahan-gis-verification-create') &&
            openGisEdit
          " :data="data.main_lahan" :questions="data.lahan_term_question_list.filter((x) => x.role_id == 14)
            " :answers="data.lahan_term_answer_list" :role="verifRole"
            :isCarbonProject="getProject(data.lahan_project) === 'carbon'" :polygonGisArea="polygonGisArea" @success="
              componentKey += 1;
            openGisEdit = false;
            getData();
            " @polygon_change="onChangePolygon" @close="
              openGisEdit = false;
            verifRole = null;
            " />

          <lahan-verification-fc-complete-data v-if="
            data.main_lahan &&
            $_sys.isAllowed('lahan-fc-verification-create') &&
            openFcCompleteData
          " :data="data.main_lahan" :role="verifRole" :isCarbonProject="getProject(data.lahan_project) === 'carbon'"
            @success="
              componentKey += 1;
            openFcCompleteData = false;
            getData();
            " @close="
              openFcCompleteData = false;
            verifRole = null;
            " />

          <lahan-verification-fc-non-carbon v-if="
            data.main_lahan &&
            $_sys.isAllowed('lahan-fc-verification-create') &&
            openFcNonCarbon
          " :data="data.main_lahan" :role="verifRole" :isCarbonProject="getProject(data.lahan_project) === 'carbon'"
            @success="
              componentKey += 1;
            openFcNonCarbon = false;
            getData();
            " @close="
              openFcNonCarbon = false;
            verifRole = null;
            " />
          <lahan-verification-fc v-if="
            data.main_lahan &&
            $_sys.isAllowed('lahan-fc-verification-create') &&
            openFc
          " :data="data.main_lahan" :questions="data.lahan_term_question_list.filter((x) => x.role_id == 19)
            " :answers="data.lahan_term_answer_list" :role="verifRole"
            :isCarbonProject="getProject(data.lahan_project) === 'carbon'" @success="
              componentKey += 1;
            openFc = false;
            getData();
            " @close="
              openFc = false;
            verifRole = null;
            " />

          <lahan-assestment v-if="
            data.main_lahan &&
            openFcAssestment
          " :data="data.main_lahan" :questions="data.lahan_term_question_list.filter((x) => x.role_id == 19)
          " :answers="data.lahan_term_answer_list" :assestment="data.lahan_assestment ?? data.main_lahan" :role="verifRole"
            :isCarbonProject="getProject(data.lahan_project) === 'carbon'" :create="data.lahan_assestment ?? 'CREATE'" @success="
              componentKey += 1;
            openFcAssestment = false;
            getData();
            " @close="
              openFcAssestment = false;
            verifRole = null;
            " />

          <lahan-verification-um v-if="
            data.main_lahan &&
            $_sys.isAllowed('lahan-um-verification-create') &&
            openUm
          " :data="data.main_lahan" :role="verifRole" :isCarbonProject="getProject(data.lahan_project) === 'carbon'"
            @success="
              componentKey += 1;
            openUm = false;
            getData();
            " @close="
              openUm = false;
            verifRole = null;
            " />


          <div v-if="data.main_lahan" class="lahan-side-list" v-for="(item, i) in sideTop"
            :key="'land-detail-row-' + i">
            <h4>{{ item.name }}</h4>

            <div class="lahan-side-item-wrapper">
              <div class="lahan-side-item" v-for="(v, j) in item.items" :key="'lahan-item' + i + j"
                v-if="!v.project || (v.project && v.project === getProject())">
                <p class="mb-0 label">{{ v.label }}</p>
                <div class="value">
                  <p class="mb-0 value qrcode" v-if="v.type === 'qrcode'">
                    <qr-code :text="data.main_lahan.barcode" />
                  </p>
                  <span class="mb-0 value" v-if="v.key === 'project'">
                    <div v-if="v.key === 'project'" v-for="(project, j) in data.lahan_project" :key="`project-${j}`"
                      class="d-flex flex-col">
                      <span>{{ project.projects_project_name }}</span>
                      <div class="d-flex flex-row mb-2">
                        <span class="badge" :class="{
                          'bg-info':
                            project.project_planting_purposes_code ==
                            'carbon',
                          'bg-light':
                            project.project_planting_purposes_code !=
                            'carbon',
                        }">{{ project.project_planting_purposes_name }}</span>
                      </div>
                    </div>
                  </span>
                  <p class="mb-0 value" v-if="v.type !== 'qrcode' && v.key !== 'project'">
                    <span v-if="v.prepend">{{ v.prepend }}</span>
                    <span :class="{
                      [v.class || '']: true,
                    }">{{
                      getValue(v.key) | parse(v.transform || "no-empty")
                    }}</span>
                    <span v-if="v.append">{{ v.append }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="lahan-side-list">
            <h4>Status &amp; Log</h4>

            <div class="lahan-side-item-wrapper" v-if="data.main_lahan">
              <lahan-detail-status-badge :data="data" :projectPurpose="getProject()" />

              <div class="lahan-side-item" v-if="
                data.main_lahan &&
                data.main_lahan.approve == 2 &&
                getProject() === 'carbon'
              ">
                <p class="mb-0 label" v-if="getProject() === 'carbon'">
                  Status MOU
                </p>
                <div class="d-flex flex-row value">
                  <span class="badge" :class="{
                    'bg-warning':
                      !mouData || (mouData && mouData.mou_status == 0),
                    'bg-primary': mouData && mouData.mou_status == 1,
                    'bg-danger': mouData && mouData.mou_status == 2,
                    'bg-info': mouData && mouData.mou_status == 3,
                    'bg-success':
                      mouData && [4, 5].includes(mouData.mou_status),
                  }">
                    <span v-if="!mouData || (mouData && mouData.mou_status == 0)">Belum Dicetak</span>
                    <span v-else-if="mouData && mouData.mou_status == 1">MOU Sudah Dicetak</span>
                    <span v-else-if="mouData && mouData.mou_status == 2">MOU Butuh Revisi</span>
                    <span v-else-if="mouData && mouData.mou_status == 3">Ready to be sign</span>
                    <span v-else-if="mouData && mouData.mou_status == 4">Signed</span>
                    <span v-else-if="mouData && mouData.mou_status == 5">Terverifikasi</span>
                  </span>
                </div>
              </div>

              <div class="lahan-side-item">
                <p class="mb-0 label">Jumlah Bibit</p>
                <div class="d-flex flex-row value">
                  <span v-if="data.main_lahan.seed_is_modified == 1" class="badge" :class="{
                    'bg-danger': data.main_lahan.seed_verify_status == 0,
                    'bg-warning': data.main_lahan.seed_verify_status == null,
                    'bg-success': data.main_lahan.seed_verify_status == 1,
                  }">
                    <span v-if="data.main_lahan.seed_verify_status == 0">Tidak Disetujui</span>
                    <span v-else-if="data.main_lahan.seed_verify_status == 1">Disetujui</span>

                    <span v-else>Menunggu Approval</span>
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="lahan-side-item" v-if="getProject() === 'carbon'">
                <p class="mb-0 label">Eligibilitas Lahan by GIS</p>
                <div class="d-flex flex-row value">
                  <span class="badge" :class="{
                    'bg-danger': data.main_lahan.gis_eligibility_status == 0,
                    'bg-success': data.main_lahan.gis_eligibility_status == 1,
                    'bg-warning': data.main_lahan.gis_eligibility_status == null
                  }">
                    <span v-if="data.main_lahan.gis_eligibility_status == 0">Not Eligible</span>
                    <span v-else-if="data.main_lahan.gis_eligibility_status == 1">Eligible</span>
                    <span v-else>Belum Diverifikasi</span>
                  </span>
                </div>
              </div>


              <div class="lahan-side-item" v-if="getProject() === 'carbon'">
                <p class="mb-0 label">Eligibilitas Lahan by UM</p>
                <div class="d-flex flex-row value">
                  <span class="badge" :class="{
                    'bg-danger': data.main_lahan.eligible_status == 0,
                    'bg-warning': data.main_lahan.eligible_status == 1,
                    'bg-success': data.main_lahan.eligible_status == 2,
                  }">
                    <span v-if="data.main_lahan.eligible_status == 0">Tidak Bisa Ikut</span>
                    <span v-else-if="data.main_lahan.eligible_status == 1">Bisa Ikut Dengan Tindakan Tambahan</span>
                    <span v-else-if="data.main_lahan.eligible_status == 2">Bisa Ikut</span>
                    <span v-else>-</span>
                  </span>
                </div>
              </div>

              <div class="lahan-side-item d-flex flex-col" style="flex-direction: column !important">
                <p class="mb-0 label">Log Data</p>
                <lahan-detail-log-data :data="data" :projectPurpose="getProject()" />
              </div>
            </div>
          </div>
          <div v-if="data.main_lahan" class="lahan-side-list" v-for="(item, i) in side" :key="'land-detail-row-' + i">
            <h4>{{ item.name }}</h4>

            <div class="lahan-side-item-wrapper">
              <div class="lahan-side-item" v-for="(v, j) in item.items" :key="'lahan-item' + i + j"
                v-if="!v.project || (v.project && v.project === getProject())">
                <p class="mb-0 label">{{ v.label }}</p>
                <div class="value">
                  <p class="mb-0 value qrcode" v-if="v.type === 'qrcode'">
                    <qr-code :text="data.main_lahan.barcode" />
                  </p>
                  <span class="mb-0 value" v-if="v.key === 'project'">
                    <div v-if="v.key === 'project'" v-for="(project, j) in data.lahan_project" :key="`project-${j}`"
                      class="d-flex flex-col">
                      <span>{{ project.projects_project_name }}</span>
                      <div class="d-flex flex-row mb-2">
                        <span class="badge" :class="{
                          'bg-info':
                            project.project_planting_purposes_code ==
                            'carbon',
                          'bg-light':
                            project.project_planting_purposes_code !=
                            'carbon',
                        }">{{ project.project_planting_purposes_name }}</span>
                      </div>
                    </div>
                  </span>
                  <p class="mb-0 value" v-if="v.type !== 'qrcode' && v.key !== 'project'">
                    <span v-if="v.prepend">{{ v.prepend }}</span>
                    <span :class="{
                      [v.class || '']: true,
                    }">{{
                      getValue(v.key) | parse(v.transform || "no-empty")
                    }}</span>
                    <span v-if="v.append">{{ v.append }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col lg="8">
      <v-card data-aos="fade-up" data-aos-delay="100" data-aos-duration="800" class="scooping-visit-detail-card mb-5">
        <v-card-title>
          <h5 class="mb-0 pb-0">Polygon, Pohon &amp; Lainnya</h5>
        </v-card-title>
        <div class="alert bg-info-light px-3 mx-4 py-2 d-flex flex-row br-8 mb-4" v-if="
          data.main_lahan &&
          data.main_lahan.updated_gis &&
          data.main_lahan.updated_gis.toLowerCase() == 'sudah' &&
          data.main_lahan.gis_polygon_area
        ">
          <v-icon large class="text-info">mdi-information</v-icon>
          <span class="text-info d-block pl-2">Luas lahan dan luas tanam dihitung berdasarkan luas polygon
            lahan</span>
        </div>
        <div class="lahan-stat-list">
          <div class="lahan-stat-item">
            <p class="mb-0 label">Tutupan Lahan</p>
            <p class="mb-0 value">
              <span v-if="data.main_lahan">{{ data.main_lahan.tutupan_lahan }}%</span>
            </p>
          </div>
          <div class="lahan-stat-item">
            <p class="mb-0 label">Luas Lahan</p>
            <p class="mb-0 value" v-if="data.main_lahan">
              <span v-if="
                data.main_lahan &&
                data.main_lahan.updated_gis.toLowerCase() == 'belum'
              ">{{ data.main_lahan.land_area | parse("ts") }} m&sup2;</span>
              <span v-else-if="
                data.main_lahan &&
                data.main_lahan.updated_gis.toLowerCase() == 'sudah' &&
                data.main_lahan.gis_polygon_area
              ">{{
                data.main_lahan.gis_polygon_area | parse("ts")
              }}
                m&sup2;</span>
            </p>
          </div>
          <div class="lahan-stat-item">
            <p class="mb-0 label">Luas Tanam</p>
            <p class="mb-0 value" v-if="data.main_lahan">
              <!-- <span
                v-if="data.main_lahan && data.main_lahan.updated_gis == 'sudah'"
                >{{
                  ((data.main_lahan.planting_area / 100) *
                    data.main_lahan.land_area)
                    | parse("ts")
                }}
                m&sup2;</span
              > -->
              <span v-if="
                data.main_lahan &&
                data.main_lahan.updated_gis.toLowerCase() == 'belum'
              ">{{ data.main_lahan.planting_area | parse("ts") }} m&sup2;</span>
              <span v-else-if="
                data.main_lahan &&
                data.main_lahan.updated_gis.toLowerCase() == 'sudah' &&
                data.main_lahan.gis_polygon_area
              ">{{
                data.main_lahan.gis_planting_area | parse("ts")
              }}
                m&sup2;</span>
            </p>
          </div>
        </div>
        <div class="polygon-wrapper">
          <div class="map-wrapper" id="map-wrapper" style="height: 400px; width: 100%">
            <div class="map-legends">
              <div class="map-legend-item" v-for="(item, i) in legends" :key="item.id" :class="{
                active: item.show,
                disabled: item.disabled,
              }" @click="toggleLayer(item, i)">
                <span v-if="item.id !== 'map-coordinate'" class="shape" :class="item.shape">
                </span>
                <span v-else-if="item.id == 'map-coordinate'"><v-icon class="icon">mdi-map-marker</v-icon></span>
                <span>{{ item.label }}</span>
              </div>
            </div>
            <div ref="mapContainer" class="map-container" v-if="mapOpen"></div>
            <div class="map-placeholder" v-else>
              <v-btn variant="success" @click="openMaps">
                <v-icon>mdi-google-maps</v-icon>
                <span>Buka Maps</span>
              </v-btn>
            </div>
          </div>

          <div class="lahan-photo-list d-flex flex-row" v-if="data.main_lahan">
            <div v-if="
              data.main_lahan.photo1 !== '-' ||
              data.main_lahan.photo2 !== '-' ||
              data.main_lahan.photo3 !== '-' ||
              data.main_lahan.photo4 !== '-'
            " class="lahan-photo-item" v-for="(item, i) in [1, 2, 3, 4]" :key="'lahan-photo' + i" @click="
              showLightbox(
                $_config.baseUrlUpload + '/' + data.main_lahan[`photo${item}`]
              )
              " v-bind:style="{
                backgroundImage:
                  'url(' +
                  $_config.baseUrlUpload +
                  '/' +
                  data.main_lahan[`photo${item}`] +
                  ')',
              }">
              <h6>Foto Lahan {{ item }}</h6>
            </div>
          </div>

          <div class="trees">
            <div class="d-flex flex-row align-items-center justify-content-between">
              <h4 class="mb-0 pb-0">Pohon</h4>
              <div class="trees-filter" v-if="trees.length > 1">
                <v-btn v-for="(tree, i) in trees" :variant="tree.label != treesActive ? 'light' : 'success'"
                  :key="`lahan-detail-tree-${i}`" class="mr-2" :class="{
                    'font-weight-bold': tree.label == treesActive,
                  }" @click="treesActive = tree.label">{{ tree.label }}
                </v-btn>
              </div>
            </div>

            <div class="d-flex flex-row align-items-center bg-warning-light px-3 py-3 br-8 mb-4 mt-3" v-if="
              data.main_lahan &&
              data.main_lahan.updated_gis == 'belum' &&
              Array.isArray(data.lahan_project) &&
              data.lahan_project.length > 0 &&
              data.lahan_project[0].project_planting_purposes_code == 'carbon'
            ">
              <v-icon large class="text-warning mr-3">mdi-information</v-icon>
              <span class="text-warning" v-if="
                data.main_lahan.pohon_kayu == 0 &&
                data.main_lahan.pohon_mpts == 0 &&
                data.main_lahan.seed_is_modified == null
              ">Untuk <strong>Project Carbon</strong>, data jumlah bibit akan
                diperbarui setelah data lahan dan polygon lahan diverifikasi
                oleh GIS. Update jumlah bibit dapat dilakukan melalui GEKO
                Mobile App</span>

              <span class="text-warning" v-if="
                (data.main_lahan.pohon_kayu > 0 ||
                  data.main_lahan.pohon_mpts > 0) &&
                data.main_lahan.seed_is_modified == null
              ">Untuk <strong>Project Carbon</strong>, data jumlah bibit masih
                perkiraan. Jumlah bibit dapat diperbarui melalui GEKO Mobile App
                setelah data lahan dan polygon lahan diverifikasi oleh
                GIS.</span>
            </div>

            <div class="d-flex flex-row align-items-center bg-info-light px-2 py-2 br-8 mt-3" v-if="
              $_sys.isAllowed('lahan-um-verification-create') &&
              data.main_lahan &&
              data.main_lahan.seed_is_modified == 1 &&
              data.main_lahan.seed_verify_status !== 1
            ">
              <v-icon large class="text-info">mdi-information</v-icon>
              <span class="text-info pl-3">Data jumlah bibit sudah diperbarui, silahkan verifikasi jumlah
                bibit dan status eligibilitas lahan
              </span>
            </div>

            <div class="tree-list">
              <div class="tree-item" v-for="(item, i) in Array.isArray(trees) &&
                trees.find((x) => x.label == treesActive) &&
                trees.find((x) => x.label == treesActive).data
                ? trees.find((x) => x.label == treesActive).data
                : []">
                <v-icon>mdi-tree</v-icon>
                <div class="tree-item-wrapper">
                  <span class="label">Pohon {{ item.trees_tree_name }} </span>
                  <span class="value">{{ item.amount | parse("ts") }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="other-data mt-5">
            <h4 class="mb-4 text-success">Data Lahan Lainnya</h4>

            <div class="other-data-list">
              <div class="other-data-item" v-for="(item, i) in mainData" :key="'key-' + i">
                <p class="mb-0 label">{{ item[0] }}</p>
                <div class="value" v-if="data.main_lahan">
                  <span v-if="
                    item.length > 2 &&
                    item[2] == 'photo' &&
                    ![null, undefined, '', '-'].includes(
                      data.main_lahan[item[1]]
                    ) &&
                    data.main_lahan[item[1]] !== '-'
                  ">
                    <img class="main-data-img" :src="$_config.baseUrlUpload + '/' + data.main_lahan[item[1]]
                      " alt="" @click="
                        showLightbox(
                          $_config.baseUrlUpload +
                          '/' +
                          data.main_lahan[item[1]]
                        )
                        " />
                  </span>
                  <span v-else-if="
                    item.length > 2 &&
                    item[2] == 'boolean' &&
                    data.main_lahan[item[1]] !== '-' &&
                    ![null, undefined, '', '-'].includes(
                      data.main_lahan[item[1]]
                    )
                  ">
                    <span class="badge" :class="{
                      'bg-success': data.main_lahan[item[1]] == 1,
                      'bg-danger': data.main_lahan[item[1]] == 0,
                    }">
                      <v-icon v-if="data.main_lahan[item[1]] == 1">mdi-circle-fill</v-icon>
                      <v-icon v-else-if="data.main_lahan[item[1]] == 0">mdi-close-circle-fill</v-icon>
                      <span>{{
                        data.main_lahan[item[1]] == 1
                          ? "Ya"
                          : data.main_lahan[item[1]] == 0
                            ? "Tidak"
                            : "-"
                      }}
                      </span>
                    </span>
                  </span>

                  <span v-else-if="
                    ![null, undefined, '', '-'].includes(
                      data.main_lahan[item[1]]
                    )
                  ">
                    <span>{{ data.main_lahan[item[1]] }}</span>
                    <span v-if="
                      item.length > 4 &&
                      item[4] &&
                      ![null, undefined, '', '-'].includes(
                        data.main_lahan[item[1]]
                      )
                    ">{{ item[4] }}</span></span>

                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div class="questions mt-5 pt-5" v-if="getProject() === 'carbon'">
            <h4 class="mb-4 text-success">
              Kelayakan dan Kesesuaian Lahan Project
            </h4>
            <table class="eligibility-table geko-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Indikator</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(question, i) in data.lahan_term_question_list" :key="`question-${i}`">
                  <td class="text-center">{{ i + 1 }}</td>
                  <td>{{ question.question }}</td>
                  <td>
                    <div class="center-v text-no-wrap">
                      <span class="badge" :class="{
                        'bg-danger':
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ) &&
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ).term_answer == 0,
                        'bg-success':
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ) &&
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ).term_answer == 1,
                      }">
                        <span class="center-v text-no-wrap" v-if="
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ) &&
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ).term_answer == 0
                        ">
                          <v-icon small class="mr-1">mdi-close-circle-outline</v-icon>
                          <span>Tidak</span>
                        </span>
                        <span class="center-v text-no-wrap" v-else-if="
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ) &&
                          data.lahan_term_answer_list.find(
                            (x) => x.term_id === question.id
                          ).term_answer == 1
                        ">
                          <v-icon small class="mr-1">mdi-check-circle-outline</v-icon>
                          <span>Ya</span>
                        </span>

                        <span v-else>-</span>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VueQRCodeComponent from "vue-qrcode-component";
import LahanGisVerification from "./LahanGisVerification.vue";
import LahanVerificationGis from "./components/LahanVerificationGis.vue";
import LahanVerificationFcCompleteData from "./components/LahanVerificationFcCompleteData.vue";
import LahanVerificationFc from "./components/LahanVerificationFc.vue";
import LahanVerificationFcNonCarbon from "./components/LahanVerificationFcNonCarbon.vue";
import LahanVerificationUm from "./components/LahanVerificationUm.vue";
import LahanMouPrint from "./components/LahanMouPrint.vue";
import LahanAppendixPrint from "./components/LahanAppendixPrint.vue";
import moment from "moment";
import LahanDetailStatusBadge from './components/LahanDetailStatusBadge.vue'
import LahanDetailLogData from "./components/LahanDetailLogData.vue";
import LahanDetailData from './LahanDetailData.js'
import LahanAssestment from "./components/LahanAssestment.vue";

export default {
  name: "land-detail",
  components: {
    "qr-code": VueQRCodeComponent,
    LahanGisVerification,
    LahanVerificationGis,
    LahanVerificationFcCompleteData,
    LahanVerificationFc,
    LahanVerificationUm,
    LahanMouPrint,
    LahanAppendixPrint,
    LahanVerificationFcNonCarbon,
    LahanDetailStatusBadge,
    LahanDetailLogData,
    LahanAssestment
  },
  methods: {
    test() {
      // const captureElement = document.querySelector(".map-wrapper");
      var mapCanvas = document.querySelector(".mapboxgl-canvas");
      mapCanvas.style.display = "block";
      const mapImage = mapCanvas.toDataURL();

      // html2canvas(captureElement, { useCORS: true })
      //   .then((canvas) => {
      //     canvas.style.display = "none";
      //     document.body.appendChild(canvas);
      //     return canvas;
      //   })
      //   .then((canvas) => {
      //     const image = canvas.toDataURL("image/png");
      //     const a = document.createElement("a");
      //     a.setAttribute("download", "my-image.png");
      //     a.setAttribute("href", image);
      //     a.click();
      //     canvas.remove();
      //   });
    },

    async onRecalculatePlantingArea() {
      if (!this.data.main_lahan) return;
      const totalTutupan = parseFloat(this.data.main_lahan.tutupan_lahan);
      const polygonArea = this.data.main_lahan.gis_polygon_area;
      const totalTutupanMeter = (totalTutupan / 100) * polygonArea;

      const plantingArea = parseFloat(polygonArea - totalTutupanMeter).toFixed(
        2
      );
      const payload = {
        lahan_no: this.data.main_lahan.lahan_no,
        gis_planting_area: plantingArea,
      };

      console.log("payload", payload);
      this.loading = true;
      const result = await this.$_api
        .post("updateGISPlantingArea", payload)
        .then(() => true)
        .catch(() => false);

      if (result) {
        this.$_alert.success("Luas tanam berhasil dihitung ulang");
      }
      this.loading = false;

      this.getData();
    },

    onOpenAppendixPrint(lahanNo) {
      if (this.data.main_lahan && this.data.main_lahan.lahan_no !== lahanNo) {
        window.open(`/#/lahan-v2?view=detail&id=${lahanNo}`);
        return;
      }
      var mapCanvas = document.querySelector(".mapboxgl-canvas");
      mapCanvas.style.display = "block";
      const mapImage = mapCanvas.toDataURL();
      this.polygonImageData = mapImage;
      this.printAppendixModal += 1;
    },

    getProject(lahanDetail) {
      try {
        return lahanDetail[0].project_planting_purposes_code;
      } catch {
        return false;
      }
    },
    toggleVerificationFc() {
      if (this.data.main_lahan.fc_complete_data === null) {
        this.openFcCompleteData = !this.openFcCompleteData;
      } else {
        this.openFc = !this.openFc;
      }

      this.verifRole =
        this.data.main_lahan.fc_complete_data == null &&
          this.data.main_lahan.updated_gis.toLowerCase() == "belum"
          ? "fc-verif-data"
          : "fc";

      if (!this.openFc && !this.openFcCompleteData) {
        console.log(this.verifRole);
      }
    },
    //refactored
    async unverificationData(type) {
      if (this.loading) return;

      if (type == "gis") {
        this.handleGisUnverification();
        return;
      }

      if (type == "um_unverification") {
        this.handleUmUnverification();

        return;
      }
      const confirmationMessage =
        type === "fc_complete_data"
          ? "Apakah anda yakin ingin unverifikasi data kelengkapan lahan?"
          : "Apakah anda yakin ingin unverifikasi jumlah bibit?";
      const apiEndpoint =
        type === "fc_complete_data"
          ? "UpdateLahanFCCompleteStatus_new"
          : "UpdateSeedAmountVerification_new";
      const successMessage =
        type === "fc_complete_data"
          ? "Kelengkapan data lahan berhasil diunverifikasi"
          : "Jumlah bibit berhasil diunverifikasi";

      const prompt = await this.$_alert.confirm(
        "Unverifikasi Data?",
        confirmationMessage,
        "Unverifikasi",
        "Batal",
        true
      );

      if (!prompt.isConfirmed) {
        this.loading = false;
        return;
      }

      this.loading = true;

      const updateCompleteData = await this.$_api
        .post(apiEndpoint, {
          current_id: this.$route.query.id,
          fc_complete_data: type === "fc_complete_data" ? 2 : undefined,
          verif_moduls: type === "um_seed" ? "unverif" : undefined,
        })
        .then(() => {
          this.$_alert.success(successMessage);
          this.componentKey += 1;
          this.getData();
          this.loading = false;
        })
        .catch(() => {
          this.$_alert.error({}, "Error", "Failed to unverify data");
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          return false;
        });
    },

    async handleGisUnverification() {
      this.loading = true;

      const prompt = await this.$_alert.confirm(
        "Unverifikasi Data GIS?",
        "",
        "Unverifikasi",
        "Batal",
        true
      );

      if (!prompt.isConfirmed) {
        this.loading = false;
        return;
      }

      let payload = {
        current_id: this.$route.query.id,
        moduls: "unverification",
        approval_status: 3,
      };

      this.$_api.post("UpdateLahanApproval_new", payload).then((res) => {
        this.$_alert.success("Lahan berhasil diunverifikasi");
        this.loading = false;
      });
    },

    async handleUmUnverification() {
      this.loading = true;

      const prompt = await this.$_alert.confirm(
        "Unverifikasi Data?",
        "",
        "Unverifikasi",
        "Batal",
        true
      );

      if (!prompt.isConfirmed) {
        this.loading = false;

        return;
      }

      const payload = {
        current_id: this.$route.query.id,
        lahan_no: this.data.main_lahan.lahan_no,
        moduls: "unverification",
        approval_status: this.data.main_lahan.approve,
        // approval_status: 2,
        fc_email: this.data.main_lahan.users_email,
      };
      console.log("payload", payload);

      this.$_api.post("UpdateLahanApproval_new", payload).then(() => {
        this.$_alert.success("Lahan berhasil diunverifikasi");
      });
    },

    async verifUmNonCarbon() {
      if (this.loading) return;
      this.loading = true;

      const isConfirmed = await this.$_alert.confirm(
        "Verifikasi Data Lahan?",
        "Apakah anda yakin ingin verifikasi data lahan?",
        "Ya, Verifikasi",
        "Batal",
        true
      );
      if (!isConfirmed.isConfirmed) {
        this.loading = false;
        return;
      }

      this.$_api
        .post("UpdateLahanApproval_new", {
          current_id: this.$route.query.id,
          moduls: "verification",
          approval_status: 2,
        })
        .then(() => {
          this.loading = false;
          this.componentKey += 1;
          this.getData();
          this.$_alert.success("Lahan berhasil diunverifikasi");
        });
    },

    getProject() {
      try {
        return this.data.lahan_project[0].project_planting_purposes_code;
      } catch {
        return null;
      }
    },

    toggleLayer(item, i) {
      var isLayerFillExist = this.map.getLayer(`${item.id}-fill`);
      if (isLayerFillExist) {
        this.map.setLayoutProperty(
          `${item.id}-fill`,
          "visibility",
          item.show ? "none" : "visible"
        );
      }

      var isLayerBorderExist = this.map.getLayer(`${item.id}-border`);
      if (isLayerBorderExist) {
        this.map.setLayoutProperty(
          `${item.id}-border`,
          "visibility",
          item.show ? "none" : "visible"
        );
      }

      if (item.id == "map-coordinate") {
        if (item.show) {
          for (const _marker of this.markers) {
            _marker.remove();
          }
          this.markers = [];
        } else {
          for (const coord of Array.isArray(this.data.lahan_polygon)
            ? this.data.lahan_polygon
            : []) {
            const marker = new mapboxgl.Marker()
              .setLngLat([coord.longitude, coord.latitude])
              .addTo(this.map);

            this.markers.push(marker);
          }
        }
      }
      if (
        !isLayerBorderExist &&
        !isLayerFillExist &&
        item.id !== "map-coordinate"
      )
        return;
      this.legends[i].show = !item.show;
    },
    getValue(data) {
      var _value = this.data;
      for (const key of data.split(".")) {
        if (_value[key]) {
          _value = _value[key];
        }
      }

      if (typeof _value === "object") return "-";

      return _value;
    },
    async getData() {
      const result = await this.$_api.get("getDetailLahan_new", {
        id: this.$route.query.id,
      });

      this.data = result;

      // set trees data
      let _trees = [];
      for (const tree of result.lahan_detail) {
        const idx = _trees.findIndex(
          (x) => x.label == tree.detail_year.substring(0, 4)
        );
        if (idx < 0) {
          _trees.push({
            label: tree.detail_year.substring(0, 4),
            data: [tree],
          });
        } else {
          _trees[idx].data.push(tree);
        }
      }

      this.trees = _trees;

      // get data mou
      try {
        const { data: resMou } = await this.$_api.get("farmer-mou/find", {
          farmer_no: result.main_lahan.farmer_no,
          program_year: this.$_config.programYear.model,
        });
        console.log("resMou", resMou);
        this.mouData = resMou;
      } catch (err) {
        console.log("getData() farmer-mou/find error", err);
        this.mouData = null;
      }

      this.openMaps();
    },

    showLightbox(imgs, index) {
      if (imgs) this.$store.state.lightbox.imgs = imgs;

      if (index) this.$store.state.lightbox.index = index;
      else this.$store.state.lightbox.index = 0;

      this.$store.state.lightbox.show = true;
    },
    async onChangePolygon(newPath) {
      const kmlGisData = await this.loadKml(
        `https://t4tadmin.kolaborasikproject.com/${newPath}`
      );
      console.log("new kml", kmlGisData);
      this.addMapLayer(kmlGisData, "map-layer-2", "#1F6200", "#97F570");
      const centerCoordinate = turf.center(kmlGisData);
      const mapCenter = centerCoordinate.geometry.coordinates;
      this.$set(this.legends, 1, {
        ...this.legends[1],
        disabled: false,
        show: true,
      });

      //calculate polygon
      if (
        Array.isArray(kmlGisData.features) &&
        kmlGisData.features.length > 0 &&
        kmlGisData.features[0].geometry
      ) {
        const polygonGis = turf.polygon(
          kmlGisData.features[0].geometry.coordinates
        );
        const area = turf.area(polygonGis);

        if (area && !this.data.main_lahan.gis_polygon_area) {
          this.polygonGisArea = parseFloat(area).toFixed(2);
        } else if (area && this.data.main_lahan.gis_polygon_area) {
          this.polygonGisArea = parseFloat(
            this.data.main_lahan.gis_polygon_area
          ).toFixed(2);
        }
      }

      if (this.map && this.map.setCenter instanceof Function) {
        this.map.setCenter(mapCenter);
      }
    },

    async loadKml(url) {
      return new Promise(async (resolve) => {
        const data = await omnivore.kml(url).on("ready", async function () {
          const geoJson = await data.toGeoJSON();
          if (geoJson.features.length > 1) {
            let _coordinates = [];

            for (const _c of geoJson.features) {
              _coordinates.push(_c.geometry.coordinates);
            }

            _coordinates.push(geoJson.features[0].geometry.coordinates);

            let _model = {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [_coordinates],
                  },
                  properties: {
                    name: "Area 1",
                    styleUrl: "#style1",
                    styleHash: "-3bc05740",
                  },
                },
              ],
            };

            return resolve(_model);
          }

          return resolve(geoJson);
        });
      });
    },

    async addMapLayer(data, id, borderColor, fillColor) {
      if (!this.map) return;
      await this.map.addSource(id, {
        type: "geojson",
        data: data,
      });

      if (fillColor) {
        await this.map.addLayer({
          id: id + "-fill",
          type: "fill",
          source: id,
          layout: {
            visibility: "visible",
          },

          mapZoom: 200,
          paint: {
            "fill-color": fillColor,
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              0.1,
              0.5,
            ],
          },
        });
      }

      await this.map.addLayer({
        id: id + "-border",
        type: "line",
        source: id,
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": borderColor,
          "line-width": 2,
        },
      });
    },

    async openMaps() {
      this.mapOpen = true;
      setTimeout(async () => {
        this.$set(this.maps, "center", [
          parseFloat(this.data.main_lahan.longitude),
          parseFloat(this.data.main_lahan.latitude),
        ]);
        mapboxgl.accessToken = this.$_config.mapBoxApi;
        this.map = await new mapboxgl.Map({
          container: this.$refs.mapContainer,
          style: this.$_config.mapBoxStyle,
          zoom: 17,
          projection: "globe",
          maxZoom: 100,
          preserveDrawingBuffer: true,
        });

        await this.map.dragRotate.disable();

        await this.map.touchZoomRotate.disableRotation();
        await this.map.addControl(new mapboxgl.FullscreenControl());
        await this.map.addControl(new mapboxgl.NavigationControl());

        this.map.on("load", async () => {
          // this.addMapLayer(kmlGisData, "Map-Layer2", "#1F6200", "#97F570");
          if (
            ![null, "-", undefined].includes(
              this.data.main_lahan.polygon_from_ff
            )
          ) {
            const kmlData = await this.loadKml(
              `https://t4tadmin.kolaborasikproject.com/${this.data.main_lahan.polygon_from_ff}`
            );

            if (kmlData.features.length > 0) {
              this.addMapLayer(kmlData, "map-layer-1", "#FF7B7B", null);
              this.$set(this.legends, 0, {
                ...this.legends[0],
                show: true,
              });

              const centerCoordinate = turf.center(kmlData);
              const mapCenter = centerCoordinate.geometry.coordinates;
              if (this.map && this.map.setCenter instanceof Function) {
                this.map.setCenter(mapCenter);
              }
            }
          }

          if (
            ![null, "-", undefined].includes(
              this.data.main_lahan.polygon_from_gis
            )
          ) {
            const kmlData = await this.loadKml(
              `https://t4tadmin.kolaborasikproject.com/${this.data.main_lahan.polygon_from_gis}`
            );
            this.addMapLayer(kmlData, "map-layer-2", "#1F6200", "#97F570");
            this.$set(this.legends, 1, {
              ...this.legends[1],
              show: true,
            });

            const centerCoordinate = turf.center(kmlData);
            const mapCenter = centerCoordinate.geometry.coordinates;
            if (this.map && this.map.setCenter instanceof Function) {
              this.map.setCenter(mapCenter);
            }
          }

          if (![null, '-', undefined].includes(this.data.main_lahan.polygon_tutupan_photo)) {
            const kmlData = await this.loadKml(
              `https://t4tadmin.kolaborasikproject.com/${this.data.main_lahan.polygon_tutupan_photo}`
            );
            this.addMapLayer(kmlData, "map-layer-3", "#FFFFFF", "#FFFFFF");
            this.$set(this.legends, 2, {
              ...this.legends[2],
              show: true,
            });
          }

          if (Array.isArray(this.data.lahan_polygon)) {
            for (const coord of this.data.lahan_polygon) {
              const marker = new mapboxgl.Marker()
                .setLngLat([
                  parseFloat(coord.longitude),
                  parseFloat(coord.latitude),
                ])
                .addTo(this.map);

              this.markers.push(marker);
            }
            if (
              [undefined, null, "", "-"].includes(
                this.data.main_lahan.polygon_from_gis
              ) &&
              [undefined, null, "", "-"].includes(
                this.data.main_lahan.polygon_from_ff
              )
            ) {
              this.map.setCenter([
                parseFloat(this.data.main_lahan.longitude),
                parseFloat(this.data.main_lahan.latitude),
              ]);
            }
            if (this.data.lahan_polygon.length > 0) {
              this.$set(this.legends, 2, {
                ...this.legends[2],
                show: true,
              });
            }
          }

          if (
            ![null, "-", undefined].includes(
              this.data.main_lahan.polygon_tutupan_photo
            )
          ) {
            const kmlData = await this.loadKml(
              `https://t4tadmin.kolaborasikproject.com/${this.data.main_lahan.polygon_tutupan_photo}`
            );

            if (kmlData.features.length > 0) {
              this.addMapLayer(kmlData, "map-layer-1", "#FF7B7B", null);
              this.$set(this.legends, 0, {
                ...this.legends[0],
                show: true,
              });

              const centerCoordinate = turf.center(kmlData);
              const mapCenter = centerCoordinate.geometry.coordinates;
              if (this.map && this.map.setCenter instanceof Function) {
                this.map.setCenter(mapCenter);
              }
            }
          }

          this.mapReady = true;
        });

        this.map.on("click", (e) => {
          console.log(e);
        });

        this.map.once("render", () => { });
      }, 1000);
    },
  },

  computed: {
    btnLabelLegalitasMOU() {
      let label = "Print MOU";
      if (this.mouData) {
        if ([1, 5].includes(this.mouData.mou_status)) label = "Preview MOU";
        else if (this.mouData.mou_status == 2) label = "Revisi MOU";
        else if (this.mouData.mou_status == 3) label = "Upload Lampiran";
        else if (this.mouData.mou_status == 4) label = "Verifikasi Lampiran";
      }

      return label;
    },
  },

  mounted() {

    this.$set(this.maps, 'accessToken', this.$_config.mapBoxApi)
    this.$set(this.maps, 'mapStyle', this.$_config.mapBoxStyle)
    this.getData();
  },
  data() {
    return LahanDetailData
  },
};
</script>
